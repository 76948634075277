@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

*{
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  /* color: #16151E; */
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-right div{
  border: 0px solid black !important;
}

.slider-left div{
  border: 0px solid black !important;
}

.slider-single-content{
  box-shadow: none !important;
}

@media (max-width: 768px){
  *{
    overflow-x: hidden;
  }
  #fontTitle{
    transform: scale(.7);
  }
  #fontTitle2{
    transform: scale(.6);
  }
  .slider-right{
    display: none !important;
  }
  
  .slider-left{
    display: none !important;
  }
  
  /* .slider-single-content{
    display: none !important;
  } */
}
